import React from "react";
import HomeBanner from "../../Assets/Home__Banner.png";
import "../../styles/home/home.css";
import Fade from "react-reveal/Fade";

const HomeMain = () => {
  return (
    <div>
      <section>
        <div
          className="Home__Banner"
          style={{
            backgroundImage: `url(${HomeBanner})`,
          }}
        >
          <div className="Home__container">
            <Fade bottom duration={1500}>
              <div className="First">
                <h1>NetForge</h1>
                <p>
                  NetForge is a leading technology company specializing 
                  in web development, mobile application development, and ERP 
                  system integration. Since our founding in 2010, we have been 
                  dedicated to delivering innovative and customized solutions 
                  to businesses across various industries. Our team of experts 
                  combines technical expertise with a deep understanding of our 
                  clients' needs, ensuring that we provide the most effective 
                  and efficient digital solutions
                </p>
                <div className="Next">
                  <div className="Get__btn">
                    <button>
                      <a href="/about">
                        <h6>Learn more</h6>
                      </a>
                    </button>
                  </div>
                  <div className="Book__btn">
                    <button>
                      <a href="/contact">
                        <h6>BOOK A DEMO</h6>
                      </a>
                    </button>{" "}
                  </div>
                </div>
              </div>
            </Fade>
            <div></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeMain;
