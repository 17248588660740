import React from "react";
import "../../styles/home/homeabout.css";
import HomeAboutImg from "../../Assets/Home__about.png";
import Pattern from "../../Assets/Pattern.png";
import Team from "../../Assets/team.png";
import Fade from "react-reveal/Fade";

const HomeAbout = () => {
  return (
    <div>
      <section>
        <Fade bottom duration={1500}>
          <div className="HA__container">
            <div>
              <div className="Abouts__Text">
                About <span className="Abouts__span">Us </span>
              </div>

              <p className="Vision__text">Vision</p>
              <h6>
              To be a global leader in technology solutions, known for our commitment to excellence, innovation, and customer satisfaction. We aim to transform businesses through digital innovation and create a lasting impact in the tech industry.
              </h6>
              <p className="Miss__header">MISSION</p>
              <h6>
              To empower businesses by providing cutting-edge digital solutions that enhance operational efficiency, drive growth, and foster innovation. We strive to build long-term partnerships with our clients by consistently delivering exceptional service and value. ETTECH Solutions’ mission includes
                <br /> • To deliver effective, efficient, reliable, accessible
                and secure Information technology solutions, products, and
                services that are relevant to the Ethiopian environment and
                fully support the country's needs.
                <br />
                • To become a high impact provider of IT products and services
                in Africa. <br />• Providing our employees with meaningful work
                and advancement opportunities.
              </h6>
              <button className="ReadMore__btn">
                <h3>
                  <a href="/about"> Read More</a>{" "}
                </h3>{" "}
              </button>
            </div>
            <div>
              <img className="Pattern-img" src={Pattern} alt="" />
            </div>
            <div>
              <img className="HAbout__img" src={HomeAboutImg} alt="" />
              <div className="Box__container">
                <div>
                  <img src={Team} alt="" />
                </div>
                <div className="Satisfed__Cont">
                  <h1>1,542</h1>
                  <h5>Satisfied Clients</h5>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
};

export default HomeAbout;
